(window as any).global = window;

// Create Element.remove() function if not exist
if (!('remove' in Element.prototype)) {
    (<any>Element.prototype).remove = function () {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}
